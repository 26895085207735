import axios from "axios";
import { generateCorrelationId } from "../functions/generateRandomChar";

const date = new Date();
const formattedDate = date.toLocaleDateString("id-ID", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
const hours = date.toLocaleTimeString("id-ID", {
  hour: "2-digit",
  hour12: false,
  minute: "2-digit",
  second: "2-digit",
});

const formattedDateAndTime = `${formattedDate} ${hours} WIB`;

const BASE_URL = process.env.REACT_APP_API_URL;
const apiToken = process.env.REACT_APP_HEADER_KEY_API;

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": apiToken,
    // "X-Correlation-Id": generateCorrelationId(),
    "X-Correlation-Id": "12345678901234567890asdf",
  },
  timeout: 1000 * 30,
});

export default {
  onLogin: (username, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/auth/login", {
          username: username,
          password: password,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getBrands: (searchBrandName) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/brand/page/1?maxCount=10&brandName=${searchBrandName}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveBrands: (brandNameInput) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/brand/", {
          brandName: brandNameInput,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  editBrands: (brandId, brandNameInput) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/brand/${brandId}`, {
          brandName: brandNameInput,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getCategories: (searchCategoryName, pageSize, currentPage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/category/page/${currentPage}?maxCount=${pageSize}&categoryName=${searchCategoryName}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveCategory: (categoryNameInput, brandIdSelected) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/category/", {
          categoryName: categoryNameInput,
          brandId: brandIdSelected,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  editCategory: (categoryId, categoryNameInput, brandIdSelected) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/category/${categoryId}`, {
          categoryName: categoryNameInput,
          brandId: brandIdSelected,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  deleteCategory: (categoryItem) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.delete(
          `/category/${parseInt(categoryItem.id)}`,
          {}
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getSupplier: (searchSupplierName, pageSize, currentPage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/supplier/page/${currentPage}?maxCount=${pageSize}&supplierName=${searchSupplierName}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveSupplier: (
    supplierNameInput,
    supplierContacNameInput,
    supplierPhoneInput,
    supplierEmailInput,
    supplierAddressInput
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/supplier/", {
          supplierName: supplierNameInput,
          supplierContacName: supplierContacNameInput,
          supplierPhone: supplierPhoneInput,
          supplierEmail: supplierEmailInput,
          supplierAddress: supplierAddressInput,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  editSupplier: (
    supplierId,
    supplierNameInput,
    supplierContacNameInput,
    supplierPhoneInput,
    supplierEmailInput,
    supplierAddressInput
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`supplier/${supplierId}`, {
          supplierName: supplierNameInput,
          supplierContacName: supplierContacNameInput,
          supplierPhone: supplierPhoneInput,
          supplierEmail: supplierEmailInput,
          supplierAddress: supplierAddressInput,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  deleteSupplier: (supplierItem) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.delete(
          `/supplier/${parseInt(supplierItem.id)}`,
          {}
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getBranch: (searchBranchName, pageSize, currentPage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/branch/page/${currentPage}?maxCount=${pageSize}&branchName=${searchBranchName}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveBranch: (
    branchNameInput,
    picNameInput,
    statusSelectedName,
    statusSelectedValue,
    branchPhoneInput,
    addressInput
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/branch/", {
          branchName: branchNameInput,
          picName: picNameInput,
          statusName: statusSelectedName,
          branchPhone: branchPhoneInput,
          statusCode: statusSelectedValue,
          address: addressInput,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  editBranch: (
    branchId,
    branchNameInput,
    picNameInput,
    statusSelectedName,
    statusSelectedValue,
    branchPhoneInput,
    addressInput
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/branch/${branchId}`, {
          branchName: branchNameInput,
          picName: picNameInput,
          statusName: statusSelectedName,
          branchPhone: branchPhoneInput,
          statusCode: statusSelectedValue,
          address: addressInput,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getProduct: (searchProductName, branchCode, pageSize, currentPage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/product/page/${currentPage}?maxCount=${pageSize}&productName=${searchProductName}&branchCode=${branchCode}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  // saveProduct: (bodyProduct) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const response = await instance.post("/product/", bodyProduct, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       resolve(response.data);
  //     } catch (error) {
  //       console.error("Login error:", error);
  //       reject(error);
  //     }
  //   });
  // },

  saveProduct: (
    categoryId,
    productName,
    categoryName,
    brandName,
    note,
    variantList
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(`/product/createsWithoutImage`, {
          categoryId: categoryId,
          productName: productName,
          categoryName: categoryName,
          brandName: brandName,
          note: note,
          attachment: variantList,
        });
        resolve(response.data);
      } catch (error) {
        console.error("error:", error);
        reject(error);
      }
    });
  },

  saveProductNewOnEdit: (masterProductCode, variantList) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/product/createAttachments/multiple/${masterProductCode}`,
          {
            attachment: variantList,
          }
        );
        resolve(response.data);
      } catch (error) {
        console.error("error:", error);
        reject(error);
      }
    });
  },

  editProduct: (
    productId,
    productName,
    brandName,
    brandId,
    categoryId,
    categoryName,
    variantList
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/product/${productId}`, {
          productName: productName,
          brandName: brandName,
          brandId: brandId,
          categoryId: categoryId,
          categoryName: categoryName,
          attachment: variantList,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  addVariant: (
    brandId,
    categoryId,
    productName,
    categoryName,
    masterProductCode,
    variantList
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post(
          `/product/createAttachments/${masterProductCode}`,
          {
            brandId: brandId,
            categoryId: categoryId,
            productName: productName,
            categoryName: categoryName,
            masterProductCode: masterProductCode,
            attachment: variantList,
          }
        );
        resolve(response.data);
      } catch (error) {
        console.error("error:", error);
        reject(error);
      }
    });
  },

  getDetailProduct: (productId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/product/detail/${productId}`);
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getTransactionData: (
    branchCode,
    transactionCode,
    dateFromSelected,
    dateToSelected,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `transaction/page/${currentPage}?maxCount=${pageSize}&branchCode=${branchCode}&minCreatedDate=${dateFromSelected}&maxCreatedDate=${dateToSelected}&note=&status=&transactionTypeCode=${transactionCode}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getClosingList: (
    branchCode,
    transactionCode,
    dateFromSelected,
    dateToSelected,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/transaction/closing/page/${currentPage}?maxCount=${pageSize}&minStartDate${dateFromSelected}=&maxStartDate=${dateToSelected}&status=${transactionCode}&BranchCode=${branchCode}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getDetailClosing: (transactionId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/transaction/closing/detail/${transactionId}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getStockCard: (
    branchCode,
    productName,
    dateFromSelected,
    dateToSelected,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/aging/page/${currentPage}?branchCode=${branchCode}&productName=${productName}&minAgingDate=${dateFromSelected}&maxAgingDate=${dateToSelected}&maxCount=${pageSize}&offset=1`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getDetailTransaction: (transactionId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/transaction/detail/${transactionId}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getDetailStock: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/stock/detail/${id}`);
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getDetailStockOpname: (transactionId, currentPageDetailSO) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/stock-opname/detail/${transactionId}/page/${currentPageDetailSO}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getInventoryData: (searchInventoryLocation, searchProductName) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/inventory/admin/page/1?branchCode=${searchInventoryLocation}&productName=${searchProductName}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getStockInData: (
    searchInventoryLocation,
    searchProductName,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/stock/in/page/${currentPage}?maxCount=${pageSize}&transactionNo=&minCreatedDate=&maxCreatedDate=&note=&status=`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getStockOutData: (
    searchInventoryLocation,
    searchProductName,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/stock/out/page/${currentPage}?maxCount=${pageSize}&transactionNo=&minCreatedDate=&maxCreatedDate=&note=&status=`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveSendingInvenStock: (
    transferDate,
    branchFromIdSelected,
    branchFromSelected,
    branchToIdSelected,
    branchToSelected,
    productTransferList
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/inventory/transfer", {
          transactionDate: transferDate,
          branchFromCode: branchFromIdSelected,
          branchFromName: branchFromSelected,
          branchToCode: branchToIdSelected,
          branchToName: branchToSelected,
          attachments: productTransferList,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  editSellingPrice: (inventoryId, statusCode, sellingPrice, minQuantity) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/inventory/${inventoryId}`, {
          status: statusCode,
          trxPrice: sellingPrice,
          minQuantity: minQuantity,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getPurchaseDataList: (
    currentPage,
    rowsPerPage,
    searchPurchaseNo,
    dateFromSelected,
    dateToSelected,
    branchCodeSelected
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/purchase-order/page/${currentPage}?maxCount=${rowsPerPage}&transactionNo=${searchPurchaseNo}&minCreatedDate=${dateFromSelected}&maxCreatedDate=${dateToSelected}&branchCode=${branchCodeSelected}&note=&status=`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getProductVariantList: (masterProductCode) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/product/page/attachment/1?maxCount=100&masterProductCode=${masterProductCode}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  saveStockIn: (
    dateStockIn,
    productOrdersLength,
    notes,
    taxCharge,
    discount,
    rounding,
    taxResto,
    edcPayment,
    sumTotalNet,
    sumTotalCash,
    paymentType,
    serviceCharge,
    additionalCost,
    paymentTypeCode,
    transactionType,
    branchCode,
    branchName,
    transactionTypeCode,
    productOrders
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/stock/in", {
          transactionDate: dateStockIn,
          item: productOrdersLength,
          note: notes,
          TaCharge: taxCharge,
          discount: discount,
          rounding: rounding,
          taxResto: taxResto,
          edcPayment: edcPayment,
          netPayment: sumTotalNet,
          cashPayment: sumTotalCash,
          paymentType: paymentType,
          serviceCharge: serviceCharge,
          additionalCost: additionalCost,
          paymentTypeCode: paymentTypeCode,
          transactionType: transactionType,
          transactionToCode: branchCode,
          transactionToName: branchName,
          transactionFromCode: branchCode,
          transactionFromName: branchName,
          transactionTypeCode: transactionTypeCode,
          attachments: productOrders,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  checkoutStockOut: (branchCode, branchName, productListStockOut) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/stock/calculate", {
          transactionFromCode: branchCode,
          transactionFromName: branchName,
          attachment: productListStockOut,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveStockOut: (
    branchCode,
    branchName,
    note,
    taxCharge,
    onlinePayment,
    rounding,
    taxResto,
    edcPayment,
    sumTotal,
    paymentName,
    serviceCharge,
    additionalCost,
    paymentTypeCode,
    dateStockOut,
    stockOutCoList
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/stock/out", {
          transactionFromCode: branchCode,
          transactionFromName: branchName,
          transactionToCode: branchCode,
          transactionToName: branchName,
          note: note,
          TaCharge: taxCharge,
          grabFood: onlinePayment,
          rounding: rounding,
          taxResto: taxResto,
          edcPayment: edcPayment,
          cashPayment: sumTotal,
          paymentType: paymentName,
          serviceCharge: serviceCharge,
          additionalCost: additionalCost,
          paymentTypeCode: paymentTypeCode,
          transactionDate: dateStockOut,
          attachments: stockOutCoList,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  savePurchaseOrder: (
    datePurchase,
    productOrdersLength,
    notes,
    taxCharge,
    discount,
    rounding,
    taxResto,
    edcPayment,
    sumTotalNet,
    sumTotalCash,
    paymentType,
    serviceCharge,
    additionalCost,
    paymentTypeCode,
    transactionType,
    branchCode,
    branchName,
    supplierCode,
    supplierName,
    transactionTypeCode,
    productOrders
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/purchase-order", {
          transactionDate: datePurchase,
          item: productOrdersLength,
          note: notes,
          TaCharge: taxCharge,
          discount: discount,
          rounding: rounding,
          taxResto: taxResto,
          edcPayment: edcPayment,
          netPayment: sumTotalNet,
          cashPayment: sumTotalCash,
          paymentType: paymentType,
          serviceCharge: serviceCharge,
          additionalCost: additionalCost,
          paymentTypeCode: paymentTypeCode,
          transactionType: transactionType,
          transactionToCode: branchCode,
          transactionToName: branchName,
          transactionFromCode: supplierCode,
          transactionFromName: supplierName,
          transactionTypeCode: transactionTypeCode,
          attachments: productOrders,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getDetailPurchase: (purchaseId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/purchase-order/detail/${purchaseId}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  finishPurchaseOrder: (detailPurchase, dataProducts) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(
          `/purchase-order/${detailPurchase.id}`,
          {
            id: detailPurchase.id,
            transactionFromCode: detailPurchase.transactionFromCode,
            transactionFromName: detailPurchase.transactionFromName,
            transactionToCode: detailPurchase.transactionToCode,
            transactionToName: detailPurchase.transactionToName,
            item: detailPurchase.item,
            serviceCharge: detailPurchase.serviceCharge,
            TaCharge: detailPurchase.TaCharge,
            taxResto: detailPurchase.taxResto,
            cashPayment: detailPurchase.cashPayment,
            discount: detailPurchase.discount,
            additionalCost: detailPurchase.additionalCost,
            edcPayment: detailPurchase.edcPayment,
            rounding: detailPurchase.rounding,
            netPayment: detailPurchase.netPayment,
            returnPayment: detailPurchase.returnPayment,
            note: detailPurchase.note,
            transactionNo: detailPurchase.transactionNo,
            transactionType: detailPurchase.transactionType,
            transactionTypeCode: detailPurchase.transactionTypeCode,
            paymentType: detailPurchase.paymentType,
            paymentTypeCode: detailPurchase.paymentTypeCode,
            status: detailPurchase.status,
            statusName: detailPurchase.statusName,
            refId: detailPurchase.refId,
            // transactionDate: detailPurchase.transactionDate,
            transactionDate: formattedDateAndTime,
            paymentDate: detailPurchase.paymentDate,
            createdDate: detailPurchase.createdDate,
            updatedDate: detailPurchase.updatedDate,
            attachments: dataProducts,
          }
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  cancelPurchases: (detailPurchase) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(
          `purchase-order/cancel/${detailPurchase.id}`,
          {}
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getUsers: (
    searchUserFullname,
    searchUsername,
    branchCode,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/user/page/${currentPage}?maxCount=${pageSize}&userFullname=${searchUserFullname}&username=${searchUsername}&branchCode=${branchCode}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveUser: (
    username,
    password,
    userEmail,
    selectedBranchName,
    employeeName,
    phone
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/auth/register", {
          username: username,
          password: password,
          userEmail: userEmail,
          branchCode: selectedBranchName,
          userFullname: employeeName,
          source: "BRANCH",
          userPhoneNumber: phone,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  editUser: (
    userId,
    usernameInput,
    passwordInput,
    emailInput,
    employeeName,
    phoneInput,
    selectedBranchName,
    userCode
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.put(`/user/${userId}`, {
          username: usernameInput,
          userName: passwordInput,
          userEmail: emailInput,
          userFullname: employeeName,
          userPhoneNumber: phoneInput,
          branchCode: selectedBranchName,
          userCode: userCode,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  reportDownload: (reportType, dateFromSelected, dateToSelected) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/transaction/admin/reportProductProvit/?transactionFromCode=&createdDateFrom=${dateFromSelected}&createdDateTo=${dateToSelected}&productCode=`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveUser_v2: (
    username,
    userEmail,
    selectedBranchName,
    employeeName,
    phone
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/user/register", {
          username: username,
          userEmail: userEmail,
          branchCode: selectedBranchName,
          userFullname: employeeName,
          source: "BRANCH",
          userPhoneNumber: phone,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  registerAuth: (username, password, userEmail, userCode, branchCode) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/auth/generateAuth", {
          username: username,
          password: password,
          userEmail: userEmail,
          userCode: userCode,
          branchCode: branchCode,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  deleteUser: (userItem) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.delete(
          `/user/${parseInt(userItem.id)}`,
          {}
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getDailySalesList: (
    branchCode,
    dateFromSelected,
    dateToSelected,
    transactionCode,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/transaction/admin/dailyProvit/page/${currentPage}?transactionFromCode=${branchCode}&productName=&productCode=&maxCount=${pageSize}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getDailySalesReport: (
    branchCode,
    dateFromSelected,
    dateToSelected,
    transactionCode,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/transaction/report?maxCount=${pageSize}&minCreatedDate=${dateFromSelected}&maxCreatedDate=${dateToSelected}&note=&status=&transactionTypeCode=${transactionCode}&transactionFromCode=${branchCode}&branchName=`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getDailyProvit: (
    branchCode,
    dateFromSelected,
    dateToSelected,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/aging/provitPage/page/${currentPage}?branchCode=${branchCode}&minAgingDate=${dateFromSelected}&maxAgingDate=${dateToSelected}&maxCount=${pageSize}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getProductProvit: (
    branchCode,
    dateFromSelected,
    dateToSelected,
    pageSize,
    currentPage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          //   `/aging/productProvitPage/page/${currentPage}?branchCode=${branchCode}&minAgingDate=${dateFromSelected}&maxAgingDate=${dateToSelected}&maxCount=${pageSize}`
          // );
          // `/transaction/admin/productProvit/page/${currentPage}?branchCode=${branchCode}&createdDateFrom=${dateFromSelected}&createdDateTo=${dateToSelected}&maxCount=${pageSize}`
          `/transaction/admin/producProvit/page/${currentPage}?branchCode=${branchCode}&createdDateFrom=${dateFromSelected}&createdDateTo=${dateToSelected}&maxCount=${pageSize}`
          // <<baseURL>>/transaction/admin/producProvit/page/1?transactionFromCode=&productName=&productCode=&createdDateFrom=&createdDateTo=
        );
        resolve(response.data);
        console.log("productProvitPage", "productProvitPage ok", response);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getCurrentStock: (branchCode, productCode, pageSize, currentPage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/inventory/admin/currentStock/page/${currentPage}?branchCode=${branchCode}&productCode=${productCode}&maxCount=${pageSize}`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  changePassword: (userId, oldPassword, newPassword) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/auth/change-password", {
          userId: userId,
          oldPassword: oldPassword,
          newPassword: newPassword,
          confNewPassword: newPassword,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getAddOns: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/masterAddons/page/1?name=&addOnsTypeCode=&isActive=true`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveAddOn: (addOnName, addOnPrice, addOnType, addOnTypeCode) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/masterAddons", {
          name: addOnName,
          price: addOnPrice,
          addOnsType: addOnType,
          addOnsTypeCode: addOnTypeCode,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  saveSettingAddOn: (
    branchCodeAddOn,
    productCodeAddOn,
    addOnId,
    productNameSelected
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/inventory/addons/bundle", {
          branchCode: branchCodeAddOn,
          productCode: productCodeAddOn,
          addonsId: addOnId,
          attachments: productNameSelected,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getStockOpnameList: (currentPage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/stock-opname/page/${currentPage}?status`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  getStockOpnameProductList: (branchCode) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `/stock-opname/list/branchCode/${branchCode}/page/1?`
        );
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },

  onSaveStockOpname: (
    dateStockOpname,
    branchNameSelected,
    totalTrxPrice,
    negativeDiffSummary,
    positiveDiffSummary,
    totalStockOpnameQuantity,
    totalStockOpnamePrice,
    totalnventoryQuantity,
    totalTrxtInvetory,
    updatedProductList
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.post("/stock-opname", {
          transactionDate: dateStockOpname,
          branchCode: branchNameSelected.branchCode,
          branchName: branchNameSelected.branchName,
          totalTrxPrice: totalTrxPrice,
          TotalMinusPrice: negativeDiffSummary.totalMinusPrice,
          TotalPlusPrice: positiveDiffSummary.totalPlusPrice,
          totalMinusQuantity: negativeDiffSummary.totalMinusQuantity,
          totalPlusQuantity: positiveDiffSummary.totalPlusQuantity,
          totalStockOpnameQuantity: totalStockOpnameQuantity,
          TotalStockOpnamePrice: totalStockOpnamePrice,
          totalnventoryQuantity: totalnventoryQuantity,
          totalTrxtInvetory: totalTrxtInvetory,
          attachments: updatedProductList,
        });
        resolve(response.data);
      } catch (error) {
        console.error("Login error:", error);
        reject(error);
      }
    });
  },
};
