import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Container, Paper, Stack } from "@mui/material";
import { FaRegTrashCan } from "react-icons/fa6";

function PopupDelete({ visible, onClose, onCancel, itemName, onDelete }) {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      PaperProps={{
        style: {
          padding: "24px",
          borderRadius: 8,
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          width: "400px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            width: 70,
            height: 70,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#f4e6fb",
            borderRadius: "50%",
          }}
        >
          <FaRegTrashCan
            style={{ width: "30px", height: "30px", color: "red" }}
          />
        </Box>
      </Box>

      <DialogContent sx={{ padding: 0, marginBottom: 2 }}>
        <DialogContentText
          sx={{
            textAlign: "center",
            color: "#111827",
            fontSize: "16px",
            fontWeight: 500,
            marginBottom: 1,
          }}
        >
          Apakah Anda yakin ingin menghapus data "{itemName}"?
        </DialogContentText>
        <DialogContentText
          sx={{
            textAlign: "center",
            color: "#6B7280",
            fontSize: "14px",
          }}
        >
          {`"${itemName}"`}
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          padding: 0,
          gap: 1,
        }}
      >
        <Button
          onClick={onCancel}
          variant="outlined"
          sx={{
            borderColor: "#E5E7EB",
            color: "#374151",
            "&:hover": {
              borderColor: "#D1D5DB",
              backgroundColor: "#F9FAFB",
            },
          }}
        >
          Cancel
        </Button>
        <Button onClick={onDelete} variant="contained" color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PopupDelete;
