import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  Spinner,
  Modal,
  FormInput,
  PickerSearch,
  PopupDelete,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadSupplier } from "../../utils/Constants";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Container, Paper, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function Supplier() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  // ==========
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  // ===========
  const [supplierList, setSupplierList] = useState([]);
  const [searchSupplierName, setSearchSupplierName] = useState("");
  // ==========
  const [supplierNameInput, setSupplierNameInput] = useState("");
  const [supplierContacNameInput, setSupplierContacNameInput] = useState("");
  const [supplierPhoneInput, setSupplierPhoneInput] = useState("");
  const [supplierEmailInput, setSupplierEmailInput] = useState("");
  const [supplierAddressInput, setSupplierAddressInput] = useState("");
  const [supplierSelected, setSupplierSelected] = useState([]);
  // ==========

  const onGetSupplier = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    try {
      const res = await api
        .getSupplier(searchSupplierName, rowsPerPage, currentPage)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setSupplierList(res.data.data);
            setTotalRecord(res.data.totalPage * res.data.limit);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onAddSupplier = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .saveSupplier(
          supplierNameInput,
          supplierContacNameInput,
          supplierPhoneInput,
          supplierEmailInput,
          supplierAddressInput
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onEditSupplier = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .editSupplier(
          supplierSelected.id,
          supplierNameInput,
          supplierContacNameInput,
          supplierPhoneInput,
          supplierEmailInput,
          supplierAddressInput
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .deleteSupplier(supplierSelected)
        .then(async (res) => {
          setIsDeleteOpen(false);
          onSuccessSubmit();
          // if (res.statusCode === 200) {
          //   setIsDeleteOpen(false);
          //   onSuccessSubmit();
          // } else {
          //   onError(res.message);
          // }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==========

  useEffect(() => {
    const fetch = async () => {
      await onGetSupplier();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    if (isReload) {
      onGetSupplier();
    }
  }, [isReload]);

  // ==========

  const onSuccessSubmit = async () => {
    setSupplierSelected([]);
    setIsDeleteOpen(false);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
    setSupplierNameInput("");
    setSupplierContacNameInput("");
    setSupplierPhoneInput("");
    setSupplierEmailInput("");
    setSupplierAddressInput("");
  };

  const onError = async (message) => {
    setIsSpinner(false);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
    setIsReload(false);
  };

  const onModalClose = () => {
    setIsOpenModal(false);
    setErrors({});
    setSupplierNameInput("");
    setSupplierContacNameInput("");
    setSupplierPhoneInput("");
    setSupplierEmailInput("");
    setSupplierAddressInput("");
  };

  const handleChangePage = (event, newPage) => {
    alert("next");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert("page select");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!supplierNameInput || supplierNameInput.trim() === "") {
      newErrors.supplierNameInput = "Supplier Name is required";
    }
    if (!supplierContacNameInput || supplierContacNameInput.trim() === "") {
      newErrors.supplierContacNameInput = "Supplier Contact is required";
    }
    if (!supplierPhoneInput || supplierPhoneInput.trim() === "") {
      newErrors.supplierPhoneInput = "Supplier Phone is required";
    }
    if (!supplierEmailInput || supplierEmailInput.trim() === "") {
      newErrors.supplierEmailInput = "Supplier Email is required";
    }
    if (!supplierAddressInput || supplierAddressInput.trim() === "") {
      newErrors.supplierAddressInput = "Supplier Address is required";
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (mode === "Add New Supplier") {
      await onAddSupplier();
    } else if (mode === "Edit Supplier") {
      await onEditSupplier();
    }
  };

  const handleEditItem = (itemSelected, indexSelected) => {
    setMode("Edit Supplier");
    setSupplierSelected(itemSelected);
    setSupplierNameInput(itemSelected.supplierName);
    setSupplierContacNameInput(itemSelected.supplierContacName);
    setSupplierPhoneInput(itemSelected.supplierPhone);
    setSupplierEmailInput(itemSelected.supplierEmail);
    setSupplierAddressInput(itemSelected.supplierAddress);
    setIsOpenModal(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...supplierList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setSupplierList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  const handleDeleteItem = (itemSelected, indexSelected) => {
    setIsDeleteOpen(true);
    setSupplierSelected(itemSelected);
  };

  // ==========

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />
      {/* <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Supplier List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("Add New Supplier");
              setIsOpenModal(true);
            }}
          >
            Add New Supplier
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <FormInput
              size="small"
              label="Search Supplier Name"
              name="searchSupplierName"
              value={searchSupplierName}
              onChange={(event) => setSearchSupplierName(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<SearchOutlined />}
              onClick={() => {
                setIsReload(true);
              }}
              sx={{
                marginTop: 1,
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableList
          tableStructure="supplier-list"
          tableName={"Supplier List"}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadSupplier}
          data={supplierList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditData={handleEditItem}
        />
      </div> */}

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Supplier List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("Add New Supplier");
              setIsOpenModal(true);
            }}
          >
            Add New Supplier
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                SUPPLIER NAME
              </Typography>
              <FormInput
                size="small"
                label="Search Supplier Name"
                name="searchSupplierName"
                value={searchSupplierName}
                onChange={(event) => setSearchSupplierName(event.target.value)}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                setIsReload(true);
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="supplier-list"
          tableName={"Supplier List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadSupplier}
          data={supplierList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditData={handleEditItem}
          handleRequestSort={handleRequestSort}
          onDeleteData={handleDeleteItem}
        />
      </Paper>

      <Modal
        modalTitle={mode}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Supplier"}
        onPressCancel={() => onModalClose()}
        onPressSave={handleSubmit}
      >
        <FormInput
          label="Supplier Name"
          name="supplierNameInput"
          error={!!errors.supplierNameInput}
          helperText={errors.supplierNameInput}
          value={supplierNameInput}
          onChange={(event) => {
            setSupplierNameInput(event.target.value);
            setErrors({});
          }}
        />

        <FormInput
          label="Supplier Contact"
          name="supplierContacNameInput"
          error={!!errors.supplierContacNameInput}
          helperText={errors.supplierContacNameInput}
          value={supplierContacNameInput}
          onChange={(event) => {
            setSupplierContacNameInput(event.target.value);
            setErrors({});
          }}
        />

        <FormInput
          label="Supplier Phone"
          name="supplierPhoneInput"
          error={!!errors.supplierPhoneInput}
          helperText={errors.supplierPhoneInput}
          value={supplierPhoneInput}
          onChange={(event) => {
            setSupplierPhoneInput(event.target.value);
            setErrors({});
          }}
        />

        <FormInput
          label="Supplier Email"
          name="supplierEmailInput"
          error={!!errors.supplierEmailInput}
          helperText={errors.supplierEmailInput}
          value={supplierEmailInput}
          onChange={(event) => {
            setSupplierEmailInput(event.target.value);
            setErrors({});
          }}
        />

        <FormInput
          label="Supplier Address"
          name="supplierAddressInput"
          error={!!errors.supplierAddressInput}
          helperText={errors.supplierAddressInput}
          value={supplierAddressInput}
          onChange={(event) => {
            setSupplierAddressInput(event.target.value);
            setErrors({});
          }}
        />
      </Modal>

      <PopupDelete
        itemName={supplierSelected.supplierName}
        visible={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onCancel={() => setIsDeleteOpen(false)}
        onDelete={() => onDelete()}
      />

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </Container>
  );
}

export default Supplier;
