import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  TableList,
  Spinner,
  Modal,
  FormInput,
  PickerSearch,
  PopupDelete,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadCategory } from "../../utils/Constants";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Container, Paper, Stack } from "@mui/material";
import { FaRegTrashCan } from "react-icons/fa6";

function Categories() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");

  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // ===========
  const [categoryList, setCategoryList] = useState([]);
  const [searchCategoryName, setSearchCategoryName] = useState("");
  // ===========
  const [categoryNameInput, setCategoryNameInput] = useState("");
  const [categorySelected, setCategorySelected] = useState([]);
  // ==========
  const [isBrandLoading, setIsBrandLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandNameSelected, setBrandNameSelected] = useState([]);
  const [searchBrandName, setSearchBrandName] = useState("");
  // ==========

  const onGetCategories = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    try {
      const res = await api
        .getCategories(searchCategoryName, rowsPerPage, currentPage)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setCategoryList(res.data.data);
            setTotalRecord(res.data.totalPage * res.data.limit);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onAddCategory = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .saveCategory(categoryNameInput, brandNameSelected.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsOpenModal(false);
            onSuccessSubmit();
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onEditCategory = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .editCategory(
          categorySelected.id,
          categoryNameInput,
          brandNameSelected.id
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsOpenModal(false);
            onSuccessSubmit();
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .deleteCategory(categorySelected)
        .then(async (res) => {
          setIsDeleteOpen(false);
          onSuccessSubmit();
          // if (res.statusCode === 200) {
          //   setIsDeleteOpen(false);
          //   onSuccessSubmit();
          // } else {
          //   onError(res.message);
          // }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==========

  useEffect(() => {
    const fetch = async () => {
      await onGetCategories();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    if (isReload) {
      onGetCategories();
    }
  }, [isReload]);

  // ==========

  const onSuccessSubmit = async () => {
    setErrors({});
    setCategorySelected([]);
    setCategoryNameInput("");
    setBrandNameSelected([]);
    setSearchBrandName("");
    setIsLoading(false);
    setIsDeleteOpen(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  const onError = async (message) => {
    setErrors({});
    setIsLoading(false);
    setIsDeleteOpen(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
    setIsReload(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!categoryNameInput || categoryNameInput.trim() === "") {
      newErrors.categoryNameInput = "Category Name is required";
    }

    return newErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (mode === "Add New Category") {
      await onAddCategory();
    } else if (mode === "Edit Category") {
      await onEditCategory();
    }
  };

  const handleEditItem = (itemSelected, indexSelected) => {
    setIsOpenModal(true);
    setMode("Edit Category");
    setCategorySelected(itemSelected);
    setCategoryNameInput(itemSelected.categoryName);
  };

  const handleDeleteItem = (itemSelected, indexSelected) => {
    setIsDeleteOpen(true);
    setCategorySelected(itemSelected);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...categoryList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setCategoryList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // ==========

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Category List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setCategorySelected([]);
              setMode("Add New Category");
              setIsOpenModal(true);
            }}
          >
            Add New Category
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CATEGORY NAME
              </Typography>
              <FormInput
                size="small"
                label="Search Category Name"
                name="searchCategoryName"
                value={searchCategoryName}
                onChange={(event) => setSearchCategoryName(event.target.value)}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                setIsReload(true);
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="category-list"
          tableName={"Category List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadCategory}
          data={categoryList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditData={handleEditItem}
          handleRequestSort={handleRequestSort}
          onDeleteData={handleDeleteItem}
        />
      </Paper>

      <Modal
        modalTitle={mode}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Category"}
        onPressCancel={() => {
          setIsOpenModal(false);
        }}
        onPressSave={() => handleSubmit()}
      >
        <FormInput
          label="Category Name"
          name="categoryNameInput"
          error={!!errors.categoryNameInput}
          helperText={errors.categoryNameInput}
          value={categoryNameInput}
          onChange={(event) => setCategoryNameInput(event.target.value)}
        />
      </Modal>

      <PopupDelete
        itemName={categorySelected.categoryName}
        visible={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onCancel={() => setIsDeleteOpen(false)}
        onDelete={() => onDelete()}
      />

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </Container>
  );
}

export default Categories;
