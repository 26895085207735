import React, { useEffect, useState } from "react";
import api from "../../helpers/api";
import {
  TableList,
  Spinner,
  Modal,
  FormInput,
  PickerSearch,
  PopupDelete,
} from "../../components";
import { Button, Grid, Typography } from "@mui/material";
import CustomizedSnackbars from "../../components/base/Snackbar";
import { getBranches } from "../../helpers/functions/fetch";
import { tableHeadUser } from "../../utils/Constants";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Container, Paper, Stack } from "@mui/material";

function Users() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalAuth, setIsOpenModalAuth] = useState(false);
  const [isOpenModalChangePass, setIsOpenModalChangePass] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  // ==========
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  // ===========
  const [userList, setUserList] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  // ==========
  const [branchList, setBranchList] = useState([]);
  const [searchFullname, setSearchFullname] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [searchBranchName, setSearchBranchName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);

  // ==========
  const [employeeName, setEmployeeName] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  // ==========
  const [usernameAuth, setUsernameAuth] = useState("");
  const [emailAuth, setEmailAuth] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [oldPasswordInput, setOldPasswordInput] = useState("");
  // ==========

  const onGetUserList = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    try {
      const res = await api
        .getUsers(
          searchFullname,
          searchUsername,
          selectedBranchName,
          rowsPerPage,
          currentPage
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setUserList(res.data.data);
            setTotalRecord(res.data.totalPage * res.data.limit);

            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newBranch = {
              id: "",
              branchName: "All Branches",
              address: "",
              branchPhone: "",
              statusName: "",
              statusCode: "",
              branchCode: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedBranchList = [newBranch, ...res.data.data];
            setBranchList(updatedBranchList);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // const onGetBranches = () => {
  //   getBranches(searchBranchName, setBranchList, onError);
  // };

  const onRegisterUser = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .saveUser_v2(
          usernameInput,
          emailInput,
          selectedBranchName,
          employeeName,
          phoneInput
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onEditUser = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .editUser(
          userSelected.id,
          usernameInput,
          passwordInput,
          emailInput,
          employeeName,
          phoneInput,
          selectedBranchName,
          userSelected.userCode
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(false);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(false);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(false);
      onError(error.message);
    }
  };

  const onHandleAuthRegister = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .registerAuth(
          usernameAuth,
          passwordInput,
          emailAuth,
          userSelected.userCode,
          userSelected.branchCode
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(false);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(false);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(false);
      onError(error.message);
    }
  };

  const onHandleChangePassword = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .changePassword(userSelected.id, oldPasswordInput, passwordInput)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(false);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(false);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(false);
      onError(error.message);
    }
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .deleteUser(userSelected)
        .then(async (res) => {
          setIsDeleteOpen(false);
          onSuccessSubmit();
          // if (res.statusCode === 200) {
          //   setIsDeleteOpen(false);
          //   onSuccessSubmit();
          // } else {
          //   onError(res.message);
          // }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==========

  useEffect(() => {
    onGetUserList();
  }, [page]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    if (isReload) {
      onGetUserList();
    }
  }, [isReload]);

  // ==========

  const onSuccessSubmit = async () => {
    setBranchNameSelected([]);
    setUserSelected([]);
    setUsernameAuth("");
    setEmployeeName("");
    setUsernameInput("");
    setEmailInput("");
    setPasswordInput("");
    setOldPasswordInput("");
    setSearchBranchName("");
    setSelectedBranchName("");
    setEmailAuth("");
    setIsLoading(false);
    setIsOpenModalAuth(false);
    setIsOpenModalChangePass(false);
    setIsDeleteOpen(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  const onCloseModal = () => {
    setBranchNameSelected([]);
    setEmployeeName("");
    setUsernameInput("");
    setEmailInput("");
    setPasswordInput("");
    setOldPasswordInput("");
    setSearchBranchName("");
    setSelectedBranchName("");
    setEmailAuth("");
    setUsernameAuth("");
    setIsOpenModal(false);
    setIsOpenModalAuth(false);
    setIsOpenModalChangePass(false);
    setErrors({});
  };

  const onError = async (message) => {
    setIsSpinner(false);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
    setIsReload(false);
  };

  const validateForm = () => {
    const newErrors = {};
    if (
      !branchNameSelected ||
      branchNameSelected === null ||
      branchNameSelected.length === 0
    ) {
      newErrors.branchNameSelected = "Branch / Outlet is required to choose";
    }

    if (!employeeName || employeeName.trim() === "") {
      newErrors.employeeName = "Employee Name is required";
    }

    if (!emailInput || emailInput.trim() === "") {
      newErrors.emailInput = "Email is required";
    }

    if (!phoneInput || phoneInput.trim() === "") {
      newErrors.phoneInput = "Phone is required";
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (mode === "add") {
      await onRegisterUser();
    } else if (mode === "edit") {
      await onEditUser();
    }
  };

  const validateFormCreateLogin = () => {
    const newErrors = {};

    if (!usernameAuth || usernameAuth.trim() === "") {
      newErrors.usernameAuth = "Username is required";
    }

    if (!passwordInput || passwordInput.trim() === "") {
      newErrors.passwordInput = "Password is required";
    }
    return newErrors;
  };

  const handleCreateLogin = async () => {
    const validationErrors = validateFormCreateLogin();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    onHandleAuthRegister();
  };

  const validateFormChangePassword = () => {
    const newErrors = {};

    if (!oldPasswordInput || oldPasswordInput.trim() === "") {
      newErrors.oldPasswordInput = "Old Password is required";
    }

    if (!passwordInput || passwordInput.trim() === "") {
      newErrors.passwordInput = "Password is required";
    }
    return newErrors;
  };

  const handleChangePass = async () => {
    const validationErrors = validateFormChangePassword();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    onHandleChangePassword();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditItem = (itemSelected, indexSelected) => {
    const branchItem = branchList.filter(
      (i) => i.branchCode === itemSelected.branchCode
    );
    setMode("edit");
    setUserSelected(itemSelected);
    setEmployeeName(itemSelected.userFullname);
    setEmailInput(itemSelected.userEmail);
    setPhoneInput(itemSelected.userPhoneNumber);
    setUsernameInput(itemSelected.username);
    setBranchNameSelected(branchItem[0]);
    setSelectedBranchName(itemSelected.branchCode);
    setIsOpenModal(true);
  };

  const handleAuthUser = (itemSelected, indexSelected) => {
    setUserSelected(itemSelected);
    setIsOpenModalAuth(true);
  };

  const handleChangePassword = (itemSelected, indexSelected) => {
    setUserSelected(itemSelected);
    setIsOpenModalChangePass(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...userList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setUserList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  const handleDeleteItem = (itemSelected, indexSelected) => {
    setIsDeleteOpen(true);
    setUserSelected(itemSelected);
  };

  // ==========

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />
      {/* <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">User List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("add");
              setIsOpenModal(true);
            }}
          >
            Add New User
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setSelectedBranchName(v.branchCode);
                  setSearchBranchName("");
                } else {
                  setSelectedBranchName("");
                  setSearchBranchName("");
                  setIsReload(true);
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
            <FormInput
              size="small"
              label="Search Username"
              name="searchUsername"
              value={searchUsername}
              onChange={(event) => setSearchUsername(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
            <FormInput
              size="small"
              label="Search Fullname"
              name="searchUsername"
              value={searchFullname}
              onChange={(event) => setSearchFullname(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsReload(true);
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableList
          tableStructure="user-list"
          tableName={"User List"}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadUser}
          data={userList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onCreateLogin={handleAuthUser}
          onEditData={handleEditItem}
          onChangePassword={handleChangePassword}
        />
      </div> */}

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Users List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("add");
              setIsOpenModal(true);
            }}
          >
            Add New User
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CHOOSE OUTLET
              </Typography>
              <PickerSearch
                dataList={branchList}
                onInputChange={(event, newInputValue) => {
                  setSearchBranchName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setSelectedBranchName(v.branchCode);
                    setSearchBranchName("");
                  } else {
                    setSelectedBranchName("");
                    setSearchBranchName("");
                    setIsReload(true);
                  }
                }}
                getOptionLabel={(branchList) => branchList.branchName || ""}
                labelPicker={"Choose Outlet"}
                placeholderPicker={"search outlet"}
                sizePicker={"small"}
                isVisibleLabelPicker={false}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                SEARCH USERNAME/EMAIL
              </Typography>
              <FormInput
                size="small"
                label="Search Username"
                name="searchUsername"
                value={searchUsername}
                onChange={(event) => setSearchUsername(event.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                SEARCH FULLNAME
              </Typography>
              <FormInput
                size="small"
                label="Search Fullname"
                name="searchUsername"
                value={searchFullname}
                onChange={(event) => setSearchFullname(event.target.value)}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                setIsReload(true);
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="user-list"
          tableName={"User List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadUser}
          data={userList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onCreateLogin={handleAuthUser}
          onEditData={handleEditItem}
          onChangePassword={handleChangePassword}
          handleRequestSort={handleRequestSort}
          onDeleteData={handleDeleteItem}
        />
      </Paper>

      <Modal
        modalTitle={`${mode === "edit" ? "Edit User" : "Add New User"}`}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save User"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={handleSubmit}
      >
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12} sm={12}>
            <PickerSearch
              value={branchNameSelected}
              dataList={branchList.filter((i) => i.branchCode !== "")}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchNameSelected(v);
                  setSelectedBranchName(v.branchCode);
                  setSearchBranchName("");
                } else {
                  setBranchNameSelected([]);
                  setSelectedBranchName("");
                  setSearchBranchName("");
                  setIsReload(true);
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Branch / Outlet"}
              placeholderPicker={"search branch / outlet"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameSelected}
              helperText={errors.branchNameSelected}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              label="Employee Name"
              name="employeeName"
              error={!!errors.employeeName}
              helperText={errors.employeeName}
              value={employeeName}
              onChange={(event) => {
                setEmployeeName(event.target.value);
                setUsernameInput(event.target.value);
                setErrors({});
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              label="Email"
              name="emailInput"
              error={!!errors.emailInput}
              helperText={errors.emailInput}
              value={emailInput}
              onChange={(event) => {
                setEmailInput(event.target.value);
                setErrors({});
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              label="Phone Number"
              name="phoneInput"
              error={!!errors.phoneInput}
              helperText={errors.phoneInput}
              value={phoneInput}
              onChange={(event) => {
                setPhoneInput(event.target.value);
                setErrors({});
              }}
            />
          </Grid>
        </Grid>
      </Modal>

      <Modal
        modalTitle={`Create Login User`}
        isVisible={isOpenModalAuth}
        buttonSaveTitle={"Save Data"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleCreateLogin()}
      >
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12} sm={12}>
            <FormInput
              label="Username"
              name="usernameAuth"
              error={!!errors.usernameAuth}
              helperText={errors.usernameAuth}
              value={usernameAuth}
              onChange={(event) => {
                setUsernameAuth(event.target.value);
                setEmailAuth(event.target.value);
                setErrors({});
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              label="Password"
              name="password"
              type="password"
              error={!!errors.passwordInput}
              helperText={errors.passwordInput}
              value={passwordInput}
              onChange={(event) => {
                setPasswordInput(event.target.value);
                setErrors({});
              }}
            />
          </Grid>
        </Grid>
      </Modal>

      <Modal
        modalTitle={`Change Password`}
        isVisible={isOpenModalChangePass}
        buttonSaveTitle={"Save Data"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleChangePass()}
      >
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12} sm={12}>
            <FormInput
              label="Old Password"
              name="password"
              type="password"
              error={!!errors.oldPasswordInput}
              helperText={errors.oldPasswordInput}
              value={oldPasswordInput}
              onChange={(event) => {
                setOldPasswordInput(event.target.value);
                setErrors({});
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              label="New Password"
              name="password"
              type="password"
              error={!!errors.passwordInput}
              helperText={errors.passwordInput}
              value={passwordInput}
              onChange={(event) => {
                setPasswordInput(event.target.value);
                setErrors({});
              }}
            />
          </Grid>
        </Grid>
      </Modal>

      <PopupDelete
        itemName={userSelected.username}
        visible={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onCancel={() => setIsDeleteOpen(false)}
        onDelete={() => onDelete()}
      />

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </Container>
  );
}

export default Users;
